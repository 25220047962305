import { BREAKPOINT } from '@constants/breakpoints'
import { PLN } from '@constants/currencies'
import { defaultAgencyPlan, FREE_PLANS } from '@constants/plans'
import { useFeatureFlags } from '@contexts/featureFlags'
import { formatPrice } from '@helpers/payment'
import { contactSupport } from '@helpers/support'
import { useBreakpoint } from '@hooks/useBreakpoint'
import {
  Button,
  Divider,
  Heading,
  Icon,
  Paragraph,
  PerfectDropdownSelect,
  Spacer,
  Spinner
} from '@landingi/landingi-ui-kit'
import {
  BlackFridayBanner,
  SalePromoBanner
} from '@pages/Authentication/components/SalePromoBanner'
import { useCreditCardStepContext } from '@pages/Authentication/contexts/creditCardStep'
import { useRegistrationConfig } from '@pages/Authentication/helpers/useRegistrationConfig'
import BruttoPrice from '@pages/Authentication/routes/CreditCardStep/components/BruttoPrice'
import NettoPrice from '@pages/Authentication/routes/CreditCardStep/components/NettoPrice'
import styles from '@pages/Authentication/routes/CreditCardStep/CreditCardStep.module.scss'
import { REGISTRATION } from '@routes/path'
import { mixpanelEvent } from '@services/mixpanel'
import { CustomLink, Spreader } from '@ui-kit'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

const SubscriptionSummaryPanel = () => {
  const { trialPeriod } = useRegistrationConfig()

  const hasSkipCreditCardStep = useFeatureFlags('SKIP_CREDIT_CARD')

  const {
    formik: {
      values,
      isSubmitting,
      isValid,
      dirty,
      setFieldValue,
      setFieldTouched,
      touched,
      errors
    },
    plansSelectOptions,
    billingPeriods,
    bruttoPrice,
    planPrice,
    currency,
    vat,
    annualSaleVat,
    hasAccessTo1MonthSale,
    discount,
    discountNet,
    skipPaymentMethod,
    hasAccessToOctoberSale,
    isAnnualSaleToggled,
    handleAnnualSaleToggle,
    hasAccessTo12MonthSale,
    annualSaleDiscountValue
  } = useCreditCardStepContext()

  const { t } = useTranslation()

  const getSelectedPlan = () =>
    plansSelectOptions.find(plan => plan.value === values.plan.value)

  const selectedPlanName = getSelectedPlan()?.label
  const selectedPlanKey = getSelectedPlan()?.value

  const navigate = useNavigate()

  const plansComparisonIsVisible = useBreakpoint(BREAKPOINT[1200], 'min')

  const planComparisonButtonIsVisible =
    plansComparisonIsVisible && selectedPlanKey !== defaultAgencyPlan

  const { period, plan } = values

  const getAnnualSaleBanner = () => (
    <BlackFridayBanner
      isChecked={isAnnualSaleToggled}
      handleToggle={handleAnnualSaleToggle}
      discount={formatPrice(annualSaleDiscountValue)}
      currency={currency}
    />
  )

  const getSelect = () => (
    <PerfectDropdownSelect
      formikKey='plan.value'
      options={plansSelectOptions}
      onChange={setFieldValue}
      onBlur={setFieldTouched}
      value={values.plan.value}
      error={errors}
      touched={touched}
      hasDescription
    />
  )

  const isFreePlan = FREE_PLANS.includes(plan.value)

  const handleContactSupport = () => contactSupport()

  const renderLabel = () => {
    if (isFreePlan) {
      return t('registration.flow.credit.card.step.start.on.free.plan', {
        days: trialPeriod
      })
    }

    return t('registration.flow.credit.card.step.start.free.trial', {
      days: trialPeriod
    })
  }

  const handleOpenComparePlans = () => {
    mixpanelEvent({
      name: '[CreditCardStep] Click Compare Plans',
      properties: {
        plan: plan.value,
        billingPeriod: period.value
      }
    })

    navigate(REGISTRATION.COMPARE_PLANS.DEFAULT)
  }

  const handleClickPeriodDropdown = () => {
    mixpanelEvent({
      name: '[CreditCardStep] Click Change Billing Period',
      properties: {
        plan: plan.value,
        billingPeriod: period.value
      }
    })
  }

  const handleChangePeriod = (key, value) => {
    if (typeof key === 'string') {
      setFieldValue(key, value)
    }

    mixpanelEvent({
      name: '[CreditCardStep] Change Billing Period on Dropdown',
      properties: {
        plan: plan.value,
        billingPeriod: period.value,
        newBillingPeriod: value
      }
    })
  }

  return (
    <Column className={styles['fixed-block']}>
      <div className={styles['subscription-summary']}>
        <Heading level={2} bold>
          {t(
            isFreePlan
              ? 'registration.flow.credit.card.subscription.summary.2022.free'
              : 'registration.flow.credit.card.subscription.summary.2022'
          )}
        </Heading>

        {planComparisonButtonIsVisible && (
          <Fragment>
            <Row justifyContent='flex-end'>
              <CustomLink variant='underlined' onClick={handleOpenComparePlans}>
                {t(
                  'registration.flow.credit.card.subscription.summary.2022.compare.plans'
                )}
              </CustomLink>
            </Row>

            <Spacer space='mini' />
          </Fragment>
        )}

        <div className={styles['subscription-summary__selects']}>
          <div className={styles['subscription-summary__plan']}>
            <Column>{t('registration.flow.credit.card.plan')}</Column>

            <Column className={styles['subscription-summary__plan-select']}>
              {plansComparisonIsVisible ? selectedPlanName : getSelect()}
            </Column>
          </div>

          {isFreePlan ? (
            t('creditcardstep.register.summary.moneyforamonth', {
              price: 0,
              currency
            })
          ) : (
            <div className={styles['subscription-summary__period']}>
              <Column>{t('registration.flow.credit.card.billed')}</Column>

              <Column className={styles['subscription-summary__period-select']}>
                <PerfectDropdownSelect
                  formikKey='period.value'
                  options={billingPeriods}
                  onChange={handleChangePeriod}
                  onBlur={setFieldTouched}
                  value={values.period.value}
                  error={errors}
                  touched={touched}
                  hasLabel={false}
                  inModal
                  handleOnOpen={handleClickPeriodDropdown}
                />
              </Column>
            </div>
          )}
        </div>

        {hasAccessTo12MonthSale && (
          <Fragment>
            <Spacer space='small' />

            {getAnnualSaleBanner()}
          </Fragment>
        )}

        <Spacer space='small' />

        <Spacer space='mini' />

        <Spacer space='tiny' />

        {!isFreePlan && (
          <Fragment>
            <Row justifyContent='space-between'>
              <Column>
                {t('registration.flow.credit.card.step.equation', {
                  price: formatPrice(planPrice / period.value),
                  currency,
                  count: period.value
                })}
              </Column>

              <Column>
                {t('registration.flow.credit.card.step.result', {
                  result: formatPrice(planPrice),
                  currency
                })}

                <NettoPrice currency={currency} />
              </Column>
            </Row>

            {isAnnualSaleToggled && hasAccessTo12MonthSale && (
              <Fragment>
                <Spacer space='small' />

                <Row justifyContent='space-between'>
                  <Column>
                    {t('registration.flow.credit.card.step.october.discount')}
                  </Column>

                  <Column>
                    {t('registration.flow.credit.card.step.discounted.price', {
                      currency,
                      discount: formatPrice(discountNet)
                    })}

                    <NettoPrice currency={currency} />
                  </Column>
                </Row>
              </Fragment>
            )}

            {currency === PLN && (
              <Fragment>
                <Spacer space='small' />

                <Row justifyContent='space-between'>
                  <Column>
                    {t('registration.flow.credit.card.step.vat.23')}
                  </Column>

                  <Column>
                    {t('registration.flow.credit.card.step.vat.value', {
                      currency,
                      vat:
                        isAnnualSaleToggled && hasAccessTo12MonthSale
                          ? annualSaleVat
                          : vat
                    })}
                  </Column>
                </Row>
              </Fragment>
            )}

            {hasAccessToOctoberSale && (
              <Fragment>
                <Spacer space='small' />

                <Row justifyContent='space-between'>
                  <Column>
                    {t('registration.flow.credit.card.step.october.discount')}
                  </Column>

                  <Column>
                    {t('registration.flow.credit.card.step.discounted.price', {
                      currency,
                      discount: formatPrice(discount)
                    })}
                    <BruttoPrice currency={currency} />
                  </Column>
                </Row>
              </Fragment>
            )}

            {hasAccessTo1MonthSale && (
              <Fragment>
                <Spacer space='small' />

                <Row justifyContent='space-between'>
                  <Column>
                    {t('registration.flow.credit.card.step.october.discount')}
                  </Column>

                  <Column>
                    {t('registration.flow.credit.card.step.discounted.price', {
                      currency,
                      discount: formatPrice(discount)
                    })}

                    <BruttoPrice currency={currency} />
                  </Column>
                </Row>
              </Fragment>
            )}

            <Spacer space='mini' />

            <Spacer space='tiny' />

            <Divider />

            <Spacer space='mini' />

            <Spacer space='tiny' />
          </Fragment>
        )}

        <Row justifyContent='space-between' alignItems='center'>
          <Column>
            <Trans
              i18nKey={
                isFreePlan
                  ? t('registration.flow.credit.card.step.total.due')
                  : t('registration.flow.credit.card.step.total.due.today')
              }
            />
          </Column>

          <Column
            className={styles['subscription-summary__total-due-today-price']}
          >
            {t('registration.flow.credit.card.step.total.due.today.price', {
              currency
            })}
          </Column>
        </Row>

        {!isFreePlan && (
          <Fragment>
            <Spacer space='small' />

            <Row justifyContent='space-between' alignItems='center'>
              <Column>
                {t('registration.flow.credit.card.step.total.due.in.days', {
                  days: trialPeriod
                })}
              </Column>

              <Column>
                {t(
                  'registration.flow.credit.card.step.total.due.in.days.price',
                  {
                    currency,
                    price: formatPrice(bruttoPrice)
                  }
                )}

                <BruttoPrice currency={currency} />
              </Column>
            </Row>
          </Fragment>
        )}

        <Spacer space='tiny' />

        {hasAccessToOctoberSale && (
          <SalePromoBanner
            promoPeriodText={t('october.sale.promo.for.3.months')}
          />
        )}

        {hasAccessTo1MonthSale && (
          <Fragment>
            <Spacer space='tiny' />

            <SalePromoBanner
              promoPeriodText={t('october.sale.promo.for.1.month')}
            />
          </Fragment>
        )}

        <Spacer space='mini' />

        {!hasAccessToOctoberSale ||
          (!hasAccessTo1MonthSale && <Spacer space='small' />)}

        <Button
          size='large'
          type='submit'
          isDisabled={!dirty || !isValid || isSubmitting}
          data-testid='submit-button'
          fitWidth
        >
          {isSubmitting ? (
            <Row justifyContent='center'>
              <Spinner />

              <Spreader spread={10} />

              {t('registration.flow.credit.card.step.loading.processing')}
            </Row>
          ) : (
            renderLabel()
          )}
        </Button>

        {hasSkipCreditCardStep && (
          <Fragment>
            <Spacer space='tiny' />

            <Button
              variant='secondary-outlined'
              onClick={skipPaymentMethod}
              size='large'
              fitWidth
            >
              {t('registration.flow.credit.card.step.skip.payment.method')}
            </Button>
          </Fragment>
        )}

        {hasAccessToOctoberSale && (
          <Fragment>
            <Spacer space='mini' />
            <Paragraph padding='none' size={10} color='accent-2'>
              {t('october.sale.discount.has.been.applied')}
            </Paragraph>
          </Fragment>
        )}

        {hasAccessTo1MonthSale && (
          <Fragment>
            <Spacer space='mini' />
            <Paragraph padding='none' size={10} color='accent-2'>
              {t('october.sale.discount.has.been.applied.1m')}
            </Paragraph>
          </Fragment>
        )}

        {hasAccessTo12MonthSale && (
          <Fragment>
            <Spacer space='mini' />
            <Paragraph padding='none' size={10} color='accent-2'>
              {t('black.friday.discount.has.been.applied')}
            </Paragraph>
          </Fragment>
        )}

        <Spacer space='mini' />

        <Spacer space='tiny' />

        <Column className={styles['subscription-summary__footer']}>
          {!isFreePlan && (
            <Row>
              <Icon
                icon='icon-ok'
                className={styles['subscription-summary__footer__icon-ok']}
              />
              <Paragraph size={12}>
                {t(
                  'registration.flow.credit.card.step.we.will.send.you.email.reminder'
                )}
              </Paragraph>
            </Row>
          )}
          <Row>
            <Icon
              icon='icon-ok'
              className={styles['subscription-summary__footer__icon-ok']}
            />
            <Paragraph size={12}>
              <Trans
                i18nKey={
                  isFreePlan
                    ? 'registration.flow.credit.card.step.you.can.change.plan.to.professional'
                    : 'registration.flow.credit.card.step.you.can.cancel'
                }
              />
            </Paragraph>
          </Row>

          <Row>
            <Icon
              icon='icon-shield'
              className={styles['subscription-summary__footer__icon-shield']}
            />
            <Paragraph size={12} padding='none'>
              {t('registration.flow.credit.card.step.30.day.money.back')}
            </Paragraph>
          </Row>
        </Column>
      </div>

      <Spacer space='medium' />

      {!hasSkipCreditCardStep && (
        <Column className={styles['contact-us-info-text-container']}>
          <Row className={styles['contact-us-info-text']}>
            <Paragraph size={14}>
              {t('registration.flow.credit.card.step.need.to.start')}{' '}
              <CustomLink
                onClick={handleContactSupport}
                className={styles['contact-us__link']}
              >
                {t('registration.flow.credit.card.step.contact.us')}
              </CustomLink>
            </Paragraph>
          </Row>
        </Column>
      )}
    </Column>
  )
}

SubscriptionSummaryPanel.displayName = 'SubscriptionSummaryPanel'

export default SubscriptionSummaryPanel
