import { NEW_LANDINGI_URL } from '@config/env'
import { useFeatureFlags } from '@contexts/featureFlags'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { LANDINGS } from '@routes/path'
import { Icon } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import styles from './Boxes.module.scss'
import { Box } from './components/Box/Box'
import { Content } from './components/Box/components/Content'
import { HoverContent } from './components/Box/components/HoverContent'

export const BoxesComponents = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { handleChangeUrl, uuid, publishingOptions, landingPageUrls } =
    usePublishingOptionsContext()

  const { isCustomDomain } = landingPageUrls

  const { isLandingPagePublished, hasLandingPageCustomDomain } =
    publishingOptions.data

  const hasAccessToSemrushIntegration = useFeatureFlags('SEMRUSH_INTEGRATION')

  const REVERSE_PROXY_PUBLICATION_FF = useFeatureFlags('REVERSE_PROXY')

  const HIDE_WORDPRESS_PUBLICATION_FF = useFeatureFlags(
    'HIDE_WORDPRESS_PUBLICATION'
  )

  const hasAccessToReserveProxy =
    REVERSE_PROXY_PUBLICATION_FF && !hasAccessToSemrushIntegration

  const shouldDomainBoxBeDisabled = !isLandingPagePublished || isCustomDomain

  const shouldWordpressOrEmbedBoxBeDisabled = !isLandingPagePublished

  return (
    <Fragment>
      {hasLandingPageCustomDomain ? (
        <Box
          Content={
            <Content
              name={t('publishing.options.boxes.box.custom_domain.name')}
              icon={
                <Icon
                  icon='icon-globe'
                  color={shouldDomainBoxBeDisabled ? 'neutral-4' : 'primary'}
                  size={26}
                />
              }
              paragraphColor={
                shouldDomainBoxBeDisabled ? 'neutral-4' : 'neutral'
              }
            />
          }
          HoverContent={
            <HoverContent
              description={t(
                'publishing.options.boxes.box.custom_domain.description'
              )}
              paragraphColor={
                shouldDomainBoxBeDisabled ? 'neutral-4' : 'neutral'
              }
            />
          }
          onClick={() => handleChangeUrl('')}
          disabled={shouldDomainBoxBeDisabled}
        />
      ) : (
        <Box
          Content={
            <Fragment>
              <div className={styles.icon}>
                <Icon icon='icon-external-link-alt' size={12} />
              </div>
              <Content
                name={t('publishing.options.boxes.box.add_domain.name')}
                icon={<Icon icon='icon-globe' color='primary' size={26} />}
                paragraphColor='neutral'
              />
            </Fragment>
          }
          HoverContent={
            <Fragment>
              <div className={styles.icon}>
                <Icon icon='icon-external-link-alt' size={12} />
              </div>
              <HoverContent
                description={t(
                  'publishing.options.boxes.box.custom_domain.description'
                )}
                paragraphColor='neutral'
              />
            </Fragment>
          }
          onClick={() =>
            window.open(`https://${NEW_LANDINGI_URL}/domains`, '_self')
          }
        />
      )}

      {!HIDE_WORDPRESS_PUBLICATION_FF && (
        <Box
          Content={
            <Content
              name={t('publishing.options.boxes.box.wordpress.name')}
              icon={
                <Icon
                  icon='icon-wordpress-alt'
                  color={
                    shouldWordpressOrEmbedBoxBeDisabled
                      ? 'neutral-4'
                      : undefined
                  }
                  size={26}
                />
              }
              paragraphColor={
                shouldWordpressOrEmbedBoxBeDisabled ? 'neutral-4' : 'neutral'
              }
            />
          }
          HoverContent={
            <HoverContent
              description={t(
                'publishing.options.boxes.box.wordpress.description'
              )}
              paragraphColor={
                shouldWordpressOrEmbedBoxBeDisabled ? 'neutral-4' : 'neutral'
              }
            />
          }
          onClick={() =>
            navigate(
              generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.WORDPRESS, {
                identifier: uuid
              })
            )
          }
          disabled={shouldWordpressOrEmbedBoxBeDisabled}
        />
      )}

      <Box
        Content={
          <Content
            name={t('publishing.options.boxes.box.embed.name')}
            icon={
              <Icon
                icon='icon-server'
                color={
                  shouldWordpressOrEmbedBoxBeDisabled ? 'neutral-4' : 'primary'
                }
                size={26}
              />
            }
            paragraphColor={
              shouldWordpressOrEmbedBoxBeDisabled ? 'neutral-4' : 'neutral'
            }
          />
        }
        HoverContent={
          <HoverContent
            description={t('publishing.options.boxes.box.embed.description')}
            paragraphColor={
              shouldWordpressOrEmbedBoxBeDisabled ? 'neutral-4' : 'neutral'
            }
          />
        }
        onClick={() =>
          navigate(
            generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.EMBED, {
              identifier: uuid
            })
          )
        }
        disabled={shouldWordpressOrEmbedBoxBeDisabled}
      />

      {hasAccessToReserveProxy && (
        <Box
          Content={
            <Content
              name={t('publishing.options.boxes.box.proxy.name')}
              icon={
                <Icon
                  icon='icon-reverse-proxy'
                  color={
                    shouldWordpressOrEmbedBoxBeDisabled
                      ? 'neutral-4'
                      : 'primary'
                  }
                  size={26}
                />
              }
              paragraphColor={
                shouldWordpressOrEmbedBoxBeDisabled ? 'neutral-4' : 'neutral'
              }
            />
          }
          HoverContent={
            <HoverContent
              description={t('publishing.options.boxes.box.proxy.description')}
              paragraphColor={
                shouldWordpressOrEmbedBoxBeDisabled ? 'neutral-4' : 'neutral'
              }
            />
          }
          onClick={() =>
            navigate(
              generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.REVERSE_PROXY, {
                identifier: uuid
              })
            )
          }
          disabled={shouldWordpressOrEmbedBoxBeDisabled}
        />
      )}
    </Fragment>
  )
}
