import { Acl } from '@components/Acl'
import { FeatureFlag } from '@components/FeatureFlag'
import { lazy } from '@helpers/lazy'
import { FullLayout } from '@layouts/FullLayout'
import { LEADS } from '@routes/path'

const LeadsPage = lazy(() =>
  import('@pages/Leads/routes/Leads').then(({ LeadsPage }) => ({
    default: LeadsPage
  }))
)

export const routes = [
  {
    path: LEADS.LEADS,
    element: (
      <FeatureFlag ff='SPA_LEADS'>
        <Acl acl='landing_pages.leads.view_list'>
          <FullLayout />
        </Acl>
      </FeatureFlag>
    ),
    children: [
      {
        path: LEADS.LEADS,
        element: <LeadsPage />
      }
    ]
  }
]
